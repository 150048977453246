import React, { useReducer } from 'react'
import AppContext from 'context'
import Reducer from './components/Reducer'
import initialState from './components/State'

const ContextWrapper = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState())
    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {children}
        </AppContext.Provider>
    )
}

export default ContextWrapper
