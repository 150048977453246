import React, { useState, useEffect } from 'react'
import StaticClockComponents from './components/StaticClockComponents'
import useStyles from './styles'

const ClassicClock = () => {
    const [now, setNow] = useState(new Date())
    const dayIndex = now.getDay()
    const date = now.getDate()
    const classes = useStyles({
        hours: now.getHours(),
        minutes: now.getMinutes(),
        seconds: now.getSeconds(),
    })

    useEffect(() => {
        const intervalId = setInterval(() => setNow(new Date()), 1000)
        return () => clearInterval(intervalId)
    }, [])

    return (
        <StaticClockComponents
            classes={classes}
            dayIndex={dayIndex}
            date={date}
        >
            <div className={classes.HoursContainer}>
                <div className={classes.Hours} />
            </div>

            <div className={classes.MinutesContainer}>
                <div className={classes.Minutes} />
            </div>

            <div className={classes.SecondsContainer}>
                <div className={classes.Seconds} />
            </div>
        </StaticClockComponents>
    )
}

export default ClassicClock
