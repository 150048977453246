import React from 'react'
import useStyles from './styles'

import ClassicClock from './components/ClassicClock'
import Quote from '../../common/Quote'

const Landing = () => {
    const classes = useStyles()

    return (
        <div className={classes.ScrollContainer}>
            <div className={classes.Landing}>
                <ClassicClock />
                <div className={classes.QuoteContainer}>
                    <Quote
                        quote="Time is what we want most, but what we use worst."
                        author="William Penn"
                    />
                </div>
            </div>
        </div>
    )
}

export default Landing
