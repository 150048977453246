import { createUseStyles } from 'react-jss'

export default createUseStyles((theme) => ({
    Button: {
        cursor: 'pointer',
        position: 'relative',
        extend: theme.flexContainer({}),
        outline: 0,
        width: theme.constants.THEME_PREVIEW_WIDTH,
        height: theme.spacing.large,
        maxHeight: theme.spacing.xlarge,
        marginBottom: theme.spacing.xsmall,
        borderRadius: theme.border.radius,
        postition: 'relative',
        border: ({ selected }) =>
            selected
                ? `${theme.border.width}px solid ${theme.colors.primary}`
                : 'none',
        backgroundColor: ({ colors }) => colors.background,
        '&:hover': {
            border: `${theme.border.width}px solid ${theme.colors.secondary}`,
            '& div:nth-child(1)': {
                transform: 'translate(0, -5px)',
            },
            '& div:nth-child(2)': {
                transform: 'translate(0, 5px)',
            },
        },
    },
    Primary: {
        transition: 'all 0.3s ease-out',
        extend: theme.flexContainer({}),
        height: theme.spacing.medium,
        backgroundColor: ({ colors }) => colors.primary,
    },
    Secondary: {
        transition: 'all 0.3s ease-out',
        extend: theme.flexContainer({}),
        height: theme.spacing.medium,
        backgroundColor: ({ colors }) => colors.secondary,
    },
}))
