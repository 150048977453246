/* eslint-disable no-bitwise */
/* eslint-disable import/prefer-default-export */
export const hexToRgb = (hex) => {
    const bigint = parseInt(hex.substring(1), 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255

    return [r, g, b].join()
}
