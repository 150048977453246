/* eslint-disable prettier/prettier */
import { createUseStyles } from 'react-jss'

export default createUseStyles((theme) => ({
    ImageAuthorLink: {
        fontSize: theme.font.size.small,
        color: theme.colors.text.onBackground,
        marginLeft: theme.spacing.xsmall,
        marginRight: theme.spacing.xsmall,
    }
}))
