import { createUseStyles } from 'react-jss'

export default createUseStyles((theme) => ({
    Logo: {
        extend: theme.flexContainer({}),
        fontSize: theme.font.size.big,
        textDecoration: 'none',
        margin: [0, theme.spacing.medium],
    },
    Label: {
        color: theme.colors.text.onBackground,
        marginLeft: theme.spacing.small,
    },
    LabelEnd: {
        fontWeight: theme.font.weight.extraBold,
        color: theme.colors.primary,
    },
}))
