import { createUseStyles } from 'react-jss'

const maxWidth = window.innerHeight - 300
const windowWidth = window.innerWidth - 40
const CLOCK_SIZE = windowWidth < maxWidth ? windowWidth : maxWidth
const FONT_SIZE = (CLOCK_SIZE / 100) * 5

const hoursGradient = (theme) => ({
    background: `linear-gradient(to right, ${theme.colors.contrast} 0%, ${theme.colors.contrast} 5%, transparent 5%, transparent 95%, ${theme.colors.contrast} 95%, ${theme.colors.contrast} 100%)`,
})

const minutesGradient = (theme) => ({
    background: `linear-gradient(to right, ${theme.colors.contrast} 0%, ${theme.colors.contrast} 3%, transparent 3%, transparent 97%, ${theme.colors.contrast} 97%, ${theme.colors.contrast} 100%)`,
})

const getRandomArbitrary = (min, max) => Math.random() * (max - min) + min

const getClipPath = () => ({
    clipPath: `polygon(
            50% ${getRandomArbitrary(13, 15)}%, 
            ${getRandomArbitrary(85, 87)}% 50%, 
            50% ${getRandomArbitrary(85, 87)}%,
            ${getRandomArbitrary(13, 15)}% 50% 
        )`,
    transition: `clip-path 500ms ease-out`,
})

export default createUseStyles((theme) => ({
    ClassicClock: {
        width: CLOCK_SIZE,
        minWidth: CLOCK_SIZE,
        maxWidth: CLOCK_SIZE,
        height: CLOCK_SIZE,
        minHeight: CLOCK_SIZE,
        maxHeight: CLOCK_SIZE,
        display: 'flex',
        flex: '1 1 auto',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.colors.text.onBackground,
        borderRadius: '51%',
        position: 'relative',
    },
    ClockBackground: {
        position: 'absolute',
        width: CLOCK_SIZE,
        height: CLOCK_SIZE,
        borderRadius: '51%',
        backgroundColor: theme.colors.secondary,
        extend: () => getClipPath(),
    },
    ClockBackgroundInner: {
        position: 'absolute',
        width: '50%',
        height: '50%',
        borderRadius: '51%',
        backgroundColor: theme.colors.background,
        extend: () => getClipPath(),
    },
    Rotate30: {
        transform: 'rotate(30deg)',
    },
    Rotate60: {
        transform: 'rotate(60deg)',
    },
    Twelve: {
        position: 'absolute',
        top: '-1%',
        left: '47%',
        fontSize: FONT_SIZE,
    },
    Three: {
        position: 'absolute',
        top: '47%',
        right: '0%',
        fontSize: FONT_SIZE,
    },
    Six: {
        position: 'absolute',
        bottom: '-1%',
        left: '48.5%',
        fontSize: FONT_SIZE,
    },
    Nine: {
        position: 'absolute',
        top: '47%',
        left: '0%',
        fontSize: FONT_SIZE,
    },
    Middle: {
        position: 'absolute',
        width: '4%',
        height: '4%',
        backgroundColor: theme.colors.primary,
        borderRadius: '51%',
    },
    HourDots: {
        position: 'absolute',
        width: CLOCK_SIZE,
        height: '1%',
        extend: hoursGradient(theme),
    },
    MinutesDots: {
        position: 'absolute',
        width: CLOCK_SIZE,
        height: '0.25%',
        extend: minutesGradient(theme),
    },
    HoursContainer: {
        position: 'absolute',
        width: '25%',
        height: '2%',
        transform: ({ hours, minutes }) =>
            `rotate(${90 + (360 * hours) / 12 + (30 * minutes) / 60}deg)`,
    },
    Hours: {
        width: '50%',
        height: '100%',
        backgroundColor: theme.colors.contrast,
    },
    MinutesContainer: {
        position: 'absolute',
        width: '76%',
        height: '1%',
        transform: ({ minutes }) => `rotate(${90 + (360 * minutes) / 60}deg)`,
    },
    Minutes: {
        width: '50%',
        height: '100%',
        backgroundColor: theme.colors.contrast,
    },
    SecondsContainer: {
        position: 'absolute',
        width: '85%',
        height: 2,
        transform: ({ seconds }) => `rotate(${90 + (360 * seconds) / 60}deg)`,
    },
    Seconds: {
        width: '50%',
        height: '100%',
        backgroundColor: theme.colors.primary,
    },
    Date: {
        display: 'flex',
        position: 'absolute',
        color: theme.colors.primary,
        fontSize: FONT_SIZE * 0.8,
        fontWeight: 600,
        right: '20%',
    },
    Day: {
        marginRight: '8%',
    },
}))
