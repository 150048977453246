import React from 'react'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import useStyles from './styles'

const Link = ({ href, small, text }) => {
    const classes = useStyles({ small })
    return (
        <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.Link}
            data-testid={`link-${href}`}
        >
            {text && !small && <span className={classes.Text}>{text}</span>}
            <OpenInNewIcon
                data-testid="icon-OpenInNewIcon"
                className={classes.SVG}
            />
        </a>
    )
}

export default Link
