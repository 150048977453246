import React from 'react'
import DateLabel from '../DateLabel'

const getAngleForMinute = (minute) => 90 + (360 * minute) / 60

const StaticClockComponents = ({ children, classes, dayIndex, date }) => (
    <div className={classes.ClassicClock}>
        <div className={classes.ClockBackground} />
        <div className={`${classes.ClockBackground} ${classes.Rotate30}`} />
        <div className={`${classes.ClockBackground} ${classes.Rotate60}`} />

        <div className={classes.ClockBackgroundInner} />
        <div
            className={`${classes.ClockBackgroundInner} ${classes.Rotate30}`}
        />
        <div
            className={`${classes.ClockBackgroundInner} ${classes.Rotate60}`}
        />

        <div className={classes.Twelve}>12</div>
        <div className={classes.Nine}>9</div>
        <div className={classes.Six}>6</div>
        <div className={classes.Three}>3</div>

        <div
            className={`${classes.HourDots}`}
            style={{ transform: `rotate(${getAngleForMinute(5)}deg)` }}
        />
        <div
            className={`${classes.HourDots}`}
            style={{ transform: `rotate(${getAngleForMinute(10)}deg)` }}
        />
        <div
            className={`${classes.HourDots}`}
            style={{ transform: `rotate(${getAngleForMinute(20)}deg)` }}
        />
        <div
            className={`${classes.HourDots}`}
            style={{ transform: `rotate(${getAngleForMinute(25)}deg)` }}
        />

        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(1)}deg)` }}
        />
        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(2)}deg)` }}
        />
        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(3)}deg)` }}
        />
        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(4)}deg)` }}
        />

        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(6)}deg)` }}
        />
        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(7)}deg)` }}
        />
        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(8)}deg)` }}
        />
        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(9)}deg)` }}
        />

        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(11)}deg)` }}
        />
        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(12)}deg)` }}
        />
        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(13)}deg)` }}
        />
        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(14)}deg)` }}
        />

        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(16)}deg)` }}
        />
        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(17)}deg)` }}
        />
        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(18)}deg)` }}
        />
        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(19)}deg)` }}
        />

        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(21)}deg)` }}
        />
        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(22)}deg)` }}
        />
        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(23)}deg)` }}
        />
        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(24)}deg)` }}
        />

        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(26)}deg)` }}
        />
        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(27)}deg)` }}
        />
        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(28)}deg)` }}
        />
        <div
            className={`${classes.MinutesDots}`}
            style={{ transform: `rotate(${getAngleForMinute(29)}deg)` }}
        />

        <DateLabel dayIndex={dayIndex} date={date} classes={classes} />

        {children}

        <div className={classes.Middle} />
    </div>
)

const shouldNotRerender = (prevProps, nextProps) => {
    const dayIndexIsEqual = prevProps.dayIndex === nextProps.dayIndex
    const dateIsEqual = prevProps.date === nextProps.date
    const themeIsEqual =
        prevProps.classes.ClassicClock === nextProps.classes.ClassicClock
    return dayIndexIsEqual && dateIsEqual && themeIsEqual
}

export default React.memo(StaticClockComponents, shouldNotRerender)
