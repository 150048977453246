import React from 'react'
import useStyles from './styles'

const Motivation = () => {
    const classes = useStyles()

    return (
        <div className={classes.Motivation}>
            <div className={classes.Name}>Motivation</div>
            <div className={classes.JobTitle}>Why I do this</div>
            <div className={classes.Attribute}>
                <div className={classes.Label}>
                    <span className={classes.Primary}>Continuous Learning</span>{' '}
                    <br /> I follow a simple rule. You are never done learning.
                </div>
            </div>
            <div className={classes.Attribute}>
                <div className={classes.Label}>
                    <span className={classes.Primary}>Passion</span> <br /> I
                    love coding. You practice what you love and what you love
                    becomes practice.
                </div>
            </div>
            <div className={classes.Attribute}>
                <div className={classes.Label}>
                    <span className={classes.Primary}>
                        Showcase & Portfolio
                    </span>
                    <br /> Show what you have learned and let others profit from
                    it. Sharing is caring.
                </div>
            </div>
        </div>
    )
}

export default Motivation
