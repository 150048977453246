const theme = {
    constants: {
        HEADER_HEIGHT: 75,
        CODE_MAX_WIDTH: 1262,
        THEME_PREVIEW_WIDTH: 310,
        SIDE_MENU_WIDTH: 400,
        MENU_ITEM_WIDTH: 250,
        QUOTE_WIDTH: 250,
        SECTION_MAX_WIDTH: 1280,
        TEXT_BOX_MAX_WIDTH: 350,
    },
    transition: {
        duration: '180ms',
        easing: 'ease-out',
    },
    spacing: {
        xxsmall: 2,
        xsmall: 4,
        small: 8,
        xmedium: 16,
        medium: 20,
        large: 40,
        xlarge: 80,
    },
    border: {
        radius: 12,
        width: 3,
    },
    font: {
        size: {
            xsmall: 12,
            small: 16,
            medium: 20,
            big: 30,
            h1: '3rem',
            h2: '1rem',
        },
        weight: {
            light: '300',
            regular: '400',
            semiBold: '600',
            bold: '700',
            extraBold: '900',
        },
    },
    image: {
        size: {
            thumb: 155,
            title: 750,
        },
    },
    lightText: {
        fontWeight: '300',
    },
    regularText: {
        fontWeight: '400',
    },
    semiBoldText: {
        fontWeight: '600',
    },
    boldText: {
        fontWeight: '700',
    },
    extraBoldText: {
        fontWeight: '900',
    },
    flexContainer: ({
        justifyContent = 'center',
        alignItems = 'center',
        flexDirection = 'row',
    }) => ({
        display: 'flex',
        flex: '1 1 auto',
        flexDirection,
        justifyContent,
        alignItems,
    }),
    CONTAINER_100x100: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
}

export default theme
