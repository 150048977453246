import { hexToRgb } from 'utils'

const ButtonStyles = (theme) => ({
    extend: theme.flexContainer({}),
    flex: 0,
    transition: `all ${theme.transition.duration} ${theme.transition.easing}`,
    background: 'transparent',
    fontSize: theme.font.size.medium,
    minWidth: theme.spacing.large,
    height: theme.spacing.large,
    borderRadius: theme.border.radius,
    color: theme.colors.text.onBackground,
    outline: 0,
    margin: 0,
    padding: 0,
    cursor: 'pointer',
    border: 0,
    '&:hover': {
        backgroundColor: `rgba(${hexToRgb(theme.colors.contrast)}, 0.2)`,
    },
    boxShadow: `${
        theme.colors.isDark
            ? 'rgba(50, 50, 50, 0.2) 0px 3px 5px 3px, rgba(0, 0, 0, 0.6) 0px 1px 3px 0px'
            : 'rgba(50, 50, 50, 0.2) 0px 2px 5px 1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'
    }`,
})

const generateComponentStyles = (theme) => ({
    button: ButtonStyles(theme),
})

export default generateComponentStyles
