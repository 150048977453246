import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Header from 'components/Header'
import Landing from 'components/pages/Landing'
import About from 'components/pages/About'
import Medium from 'components/pages/Medium'

import useStyles from './styles'

const AppEntry = () => {
    const classes = useStyles()
    return (
        <div className={classes.AppEntry}>
            <Router>
                <Header />
                <Routes>
                    <Route exact path="/" element={<Landing />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/blog" element={<Medium />} />
                </Routes>
            </Router>
        </div>
    )
}

export default AppEntry
