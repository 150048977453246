import React from 'react'
import Link from 'components/common/Link'
import useStyles from './styles'
import NetlifySvg from './icons/netlify.svg'
import GitHubSvg from './icons/github.svg'
import ReactSvg from './icons/react.svg'
import MaterialUISvg from './icons/material-ui.svg'

const ENTRIES = [
    {
        name: 'Netlify',
        image: NetlifySvg,
        style: { backgroundColor: '#0e1e25' },
        link: 'https://www.netlify.com/',
    },
    {
        name: 'GitHub',
        image: GitHubSvg,
        style: { backgroundColor: '#ffffff' },
        link: 'https://github.com/',
    },
    {
        name: 'React JS',
        image: ReactSvg,
        style: {},
        link: 'https://reactjs.org/',
    },
    {
        name: 'Material UI Icons',
        image: MaterialUISvg,
        style: {},
        link: 'https://material-ui.com/components/material-icons/',
    },
]

const TechStack = () => {
    const classes = useStyles()

    return (
        <div className={classes.TechStack}>
            <div className={classes.Name}>Techstack</div>
            <div className={classes.JobTitle}>Simple yet powerful.</div>
            {ENTRIES.map((entry) => (
                <div key={entry.name} className={classes.Attribute}>
                    <img
                        src={entry.image}
                        alt={`${entry.name} Logo`}
                        title={entry.name}
                        className={classes.SVG}
                        style={{ ...entry.style }}
                    />
                    <div className={classes.Link} title={`Open ${entry.name}`}>
                        <Link href={entry.link} />
                    </div>
                </div>
            ))}
        </div>
    )
}

export default TechStack
