import React from 'react'
import useStyles from './styles'

const ThemePreview = (props) => {
    const classes = useStyles(props)
    const { onMouseEnter, onMouseLeave, onSelect } = props
    return (
        <button
            className={classes.Button}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            type="button"
            onClick={onSelect}
        >
            <div className={classes.Primary} />
            <div className={classes.Secondary} />
        </button>
    )
}

export default ThemePreview
