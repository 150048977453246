import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import useStyles from './styles'

const MenuItem = ({ title, routeTo, onClick, imageKey }) => {
    const location = useLocation()
    const selected = location.pathname === routeTo
    const classes = useStyles({ selected, imageKey })
    return (
        <Link
            className={classes.MenuItem}
            to={routeTo}
            onClick={onClick}
            title={title}
        >
            <span className={classes.Title}>{title}</span>
        </Link>
    )
}

export default MenuItem
