import { createUseStyles } from 'react-jss'

export default createUseStyles((theme) => ({
    QuoteContainer: {
        extend: theme.flexContainer({ flexDirection: 'column' }),
        marginTop: theme.spacing.medium,
        textAlign: 'center',
        color: theme.colors.text.onBackground,
        maxWidth: theme.constants.QUOTE_WIDTH,
        minWidth: theme.constants.QUOTE_WIDTH,
        width: theme.constants.QUOTE_WIDTH,
        border: `2px solid ${theme.colors.primary}`,
        borderRadius: 4,
        position: 'relative',
    },
    Quote: {
        extend: theme.flexContainer({}),
        fontSize: theme.font.size.small,
        fontStyle: 'italic',
        padding: theme.spacing.xmedium,
    },
    Author: {
        extend: theme.flexContainer({ justifyContent: 'flex-start' }),
        width: '100%',
        fontSize: theme.font.size.xsmall,
        fontWeight: theme.font.weight.semiBold,
        color: theme.colors.secondary,
        position: 'absolute',
        bottom: -24,
        left: 64,
    },
    BorderBox: {
        position: 'absolute',
        bottom: -22,
        left: 8,
        borderTop: `2px solid ${theme.colors.primary}`,
        borderRight: `2px solid ${theme.colors.primary}`,
        borderTopRightRadius: 24,
        backgroundColor: theme.colors.background,
        width: 45,
        height: 20,
    },
    BorderCover: {
        backgroundColor: theme.colors.background,
        height: 20,
        width: 30,
        position: 'absolute',
        bottom: -20,
        left: 34,
    },
}))
