import React from 'react'

const DAYS = ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA']

const DateLabel = ({ dayIndex, date, classes } = {}) => (
    <div className={classes.Date}>
        <div className={classes.Day}>{DAYS[dayIndex]}</div>
        {date}
    </div>
)

const shouldNotRerender = (prevProps, nextProps) => {
    const dayIndexIsEqual = prevProps.dayIndex === nextProps.dayIndex
    const dateIsEqual = prevProps.date === nextProps.date
    const themeIsEqual = prevProps.classes.Date === nextProps.classes.Date
    return dayIndexIsEqual && dateIsEqual && themeIsEqual
}

export default React.memo(DateLabel, shouldNotRerender)
