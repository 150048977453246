import { createUseStyles } from 'react-jss'
import { isMobile } from 'react-device-detect'
import { LightColors } from 'theme/colors/Light'
import { DarkColors } from 'theme/colors/Dark'

export default createUseStyles((theme) => ({
    ThemeSelector: {
        extend: theme.flexContainer({ flexDirection: 'column' }),
        overflow: 'hidden',
        pointerEvents: 'auto',
        transition: `transform ${theme.transition.duration} ${theme.transition.easing}`,
        height: '100%',
        width: isMobile ? '100%' : theme.constants.SIDE_MENU_WIDTH,
        backgroundColor: theme.colors.background,
        borderLeft: `${theme.border.width}px solid ${theme.colors.secondary}`,
        transform: ({ hide }) => {
            if (hide) {
                return 'translate(105vw)'
            }

            if (isMobile) {
                return 'translate(0vw)'
            }

            return `translate(calc(100vw - ${theme.constants.SIDE_MENU_WIDTH}px))`
        },
    },
    Header: {
        extend: theme.flexContainer({ justifyContent: 'flex-end' }),
        flexGrow: 0,
        padding: theme.spacing.medium,
        width: `calc(100% - ${theme.spacing.large}px)`,
        transition: `all ${theme.transition.duration} ${theme.transition.easing}`,
    },
    ThemeSelectorContent: {
        extend: theme.flexContainer({
            justifyContent: 'flex-start',
            flexDirection: 'column',
        }),
        maxHeight: `calc(100% - ${theme.spacing.xlarge}px)`,
        overflow: 'auto',
    },
    Themes: {
        overflow: 'auto',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginBottom: theme.spacing.medium,
    },
    ThemesContainer: {
        extend: theme.flexContainer({ alignItems: 'flex-start' }),
        flexWrap: 'wrap',
        width: `calc(100% - ${theme.spacing.large}px)`,
    },
    Button: {
        extend: theme.components.button,
    },
    LightThemes: {
        extend: theme.flexContainer({ flexDirection: 'column' }),
        flexGrow: 0,
        margin: theme.spacing.medium,
        marginTop: 0,
        border: `${theme.border.width}px solid ${theme.colors.contrast}`,
        backgroundColor: LightColors.background,
        color: LightColors.text.onBackground,
        paddingBottom: theme.spacing.small,
    },
    DarkThemes: {
        extend: theme.flexContainer({ flexDirection: 'column' }),
        flexGrow: 0,
        margin: theme.spacing.medium,
        marginTop: 0,
        border: `${theme.border.width}px solid ${theme.colors.contrast}`,
        backgroundColor: DarkColors.background,
        color: DarkColors.text.onBackground,
        paddingBottom: theme.spacing.small,
    },
}))
