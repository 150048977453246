import { createUseStyles } from 'react-jss'

export default createUseStyles((theme) => ({
    ScrollContainer: {
        gap: theme.spacing.medium,
        flex: '1 1 auto',
        overflow: 'auto',
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing.medium,
        height: 'calc(100% - 116px)',
        justifyContent: 'center',
    },
}))
