import React from 'react'
import CVCard from './components/CVCard'
import Motivation from './components/Motivation'
import TechStack from './components/TechStack'
import useStyles from './styles'

const About = () => {
    const classes = useStyles()

    return (
        <div className={classes.About}>
            <CVCard />
            <Motivation />
            <TechStack />
        </div>
    )
}

export default About
