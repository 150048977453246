import React, { useContext, useState, useEffect, useCallback } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import WbSunnyIcon from '@material-ui/icons/WbSunny'
import NightsStayIcon from '@material-ui/icons/NightsStay'
import createPersistedState from 'use-persisted-state'
import { createPortal } from 'react-dom'
import AppContext from 'context'
import * as LightThemes from 'theme/colors/Light'
import * as DarkThemes from 'theme/colors/Dark'
import ThemePreview from './components/ThemePreview'
import useStyles from './styles'

const themePortalElement = document.getElementById('themeportal')
const useThemeState = createPersistedState('theme')

const ThemeSelector = () => {
    const { state, dispatch } = useContext(AppContext)
    const [hide, setHide] = useState(true)
    const classes = useStyles({ hide })
    const [themeName, setThemeName] = useThemeState('light_0')

    useEffect(() => {
        if (state.themeSelectorIsOpen) {
            setHide(false)
        }
    }, [state.themeSelectorIsOpen])

    const close = useCallback(() => {
        if (state.themeSelectorIsOpen) {
            setHide(true)
            setTimeout(() => dispatch({ type: 'onThemeSelectorClick' }), 500)
        }
    }, [dispatch, state.themeSelectorIsOpen])

    const handleKeyDown = useCallback(
        (event) => {
            if (event.keyCode === 27) {
                close()
            }
        },
        [close],
    )

    useEffect(() => {
        if (state.themeSelectorIsOpen) {
            document.addEventListener('keydown', handleKeyDown, false)
        } else {
            document.removeEventListener('keydown', handleKeyDown, false)
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown, false)
        }
    }, [handleKeyDown, state.themeSelectorIsOpen])

    if (!state.themeSelectorIsOpen) return null

    return createPortal(
        <div className={classes.ThemeSelector}>
            <div className={classes.Header}>
                <button
                    className={classes.Button}
                    type="button"
                    onClick={close}
                    aria-label="Show Theme Selector"
                >
                    <CloseIcon />
                </button>
            </div>
            <div className={classes.ThemeSelectorContent}>
                <div className={classes.LightThemes}>
                    <div className={classes.Header}>
                        <WbSunnyIcon />
                    </div>
                    <div className={classes.ThemesContainer}>
                        {Object.values(LightThemes).map((theme, index) => (
                            <ThemePreview
                                // eslint-disable-next-line react/no-array-index-key
                                key={`light_${index}`}
                                colors={theme}
                                selected={themeName === `light_${index}`}
                                onSelect={() => {
                                    setThemeName(`light_${index}`)
                                    close()
                                }}
                            />
                        ))}
                    </div>
                </div>
                <div className={classes.DarkThemes}>
                    <div className={classes.Header}>
                        <NightsStayIcon />
                    </div>
                    <div className={classes.ThemesContainer}>
                        {Object.values(DarkThemes).map((theme, index) => (
                            <ThemePreview
                                // eslint-disable-next-line react/no-array-index-key
                                key={`dark_${index}`}
                                colors={theme}
                                selected={themeName === `dark_${index}`}
                                onSelect={() => {
                                    setThemeName(`dark_${index}`)
                                    close()
                                }}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>,
        themePortalElement,
    )
}

export default ThemeSelector
