import { createUseStyles } from 'react-jss'
import { hexToRgb } from 'utils'

export default createUseStyles((theme) => ({
    Title: {
        margin: theme.spacing.small,
        fontWeight: theme.font.weight.regular,
    },
    SubTitle: {
        marginLeft: theme.spacing.small,
        color: theme.colors.secondary,
        fontSize: theme.font.size.xsmall,
    },
    MenuItem: {
        extend: theme.flexContainer({
            flexDirection: 'column',
            alignItems: 'flex-start',
        }),
        flexGrow: 0,
        transition: `all ${theme.transition.duration} ${theme.transition.easing}`,
        background: 'transparent',
        fontSize: theme.font.size.medium,
        height: ({ imageKey }) => {
            if (imageKey) return 'auto'
            return theme.spacing.large - 2 * theme.border.width
        },
        width: theme.constants.MENU_ITEM_WIDTH,
        borderRadius: theme.border.radius,
        outline: 0,
        marginBottom: theme.spacing.medium,
        marginLeft: theme.spacing.medium,
        textDecoration: 'none',
        color: theme.colors.text.onBackground,
        border: 0,
        boxShadow: ({ selected }) =>
            `${
                theme.colors.isDark
                    ? `rgba(0, 0, 0, 0.1) 0px -1px 0px, rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, ${
                          selected ? theme.colors.primary : 'rgba(0, 0, 0, 0.2)'
                      } 0px -3px 0px inset`
                    : `rgba(0, 0, 0, 0.1) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, ${
                          selected ? theme.colors.primary : 'rgba(0, 0, 0, 0.2)'
                      } 0px -3px 0px inset`
            }`,
        '&:hover': {
            backgroundColor: `rgba(${hexToRgb(theme.colors.contrast)}, 0.2)`,
        },
    },
}))
