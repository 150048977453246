import { createUseStyles } from 'react-jss'

export default createUseStyles((theme) => ({
    Header: {
        extend: theme.flexContainer({
            justifyContent: 'space-between',
            alignItems: 'center',
        }),
        background: theme.colors.background,
        height: theme.constants.HEADER_HEIGHT,
        minHeight: theme.constants.HEADER_HEIGHT,
        maxHeight: theme.constants.HEADER_HEIGHT,
    },
}))
