import React from 'react'
import ContextWrapper from './components/ContextWrapper'
import ThemeProvider from './components/ThemeProvider'
import AppEntry from './components/AppEntry'

const App = () => (
    <ContextWrapper>
        <ThemeProvider>
            <AppEntry />
        </ThemeProvider>
    </ContextWrapper>
)

export default App
