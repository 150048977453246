const Reducer = (oldState, action) => {
    const newState = { ...oldState }
    switch (action.type) {
        case 'onMenuClick':
            newState.menuIsOpen = !oldState.menuIsOpen
            break
        case 'onThemeSelectorClick':
            newState.themeSelectorIsOpen = !oldState.themeSelectorIsOpen
            break
        default:
            throw new Error('No reducer for action found.', action)
    }

    return newState
}

export default Reducer
