import { createUseStyles } from 'react-jss'

export default createUseStyles((theme) => ({
    Link: {
        transition: `box-shadow ${theme.transition.duration} ${theme.transition.easing}, background-color ${theme.transition.duration} ${theme.transition.easing}`,
        padding: theme.spacing.small,
        minWidth: ({ small }) => (small ? 9 : 24),
        height: ({ small }) => (small ? 9 : 24),
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.border.radius,
        color: theme.colors.secondary,
        '&:hover': {
            boxShadow:
                'rgba(0, 0, 0, 0.4) 0px 8px 6px, rgba(0, 0, 0, 0.1) 0px 0px 40px inset',
        },
        border: `1px solid ${theme.colors.primary}`,
    },
    SVG: {
        fontSize: '1rem !important',
    },
    Children: {
        marginRight: theme.spacing.small,
    },
    Text: {
        marginRight: theme.spacing.xsmall,
    },
}))
