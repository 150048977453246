import React from 'react'
import MediumCard from 'components/common/MediumCard'
import useStyles from './styles'

import Sergey_Zolkin from '../../../assets/sergey-zolkin-_UeY8aTI6d0-unsplash.jpg'
import athree23 from '../../../assets/board-ge60e3c7c6_640.jpg'
import Hannah_Busing from '../../../assets/hannah-busing-Zyx1bK9mqmA-unsplash.jpg'

const Medium = () => {
    const classes = useStyles()

    return (
        <div className={classes.ScrollContainer}>
            <MediumCard
                imageAuthorName="Sergey Zolkin"
                imageAuthorLink="https://unsplash.com/@szolkin"
                imageSourceName="Unsplash"
                imageSourceLink="https://unsplash.com/"
                image={Sergey_Zolkin}
                link="https://maulwurfio.medium.com/how-i-configured-jest-for-react-to-write-unit-tests-more-efficiently-79bec1d294c8"
                title="How I configured Jest for React to write unit tests more efficiently"
                date="July 8th 2022"
                text="If you are working with React I am certain that you are 
                familiar with writing unit tests with Jest and React Testing Library. 
                This article is to show you how I configured Jest to make my life easier. 
                I hope that some of the points also help you to reduce the amount of 
                repetition and boilerplate code."
            />
            <MediumCard
                imageAuthorName="athree23"
                imageAuthorLink="https://pixabay.com/users/athree23-6195572/"
                imageSourceName="pixabay"
                imageSourceLink="https://pixabay.com/photos/board-chalk-feedback-review-study-3700116/"
                image={athree23}
                link="https://maulwurfio.medium.com/14-gotchas-around-coding-challenges-for-frontend-engineers-746ffe3220bf"
                title="14 gotchas around coding challenges for Frontend Engineers"
                date="May 17th 2022"
                text="For two years I have been involved into our hiring
                    process. I am checking code challenges and I am
                    conducting technical interviews from Junior to Lead
                    seniority. Here are 14 things I am looking at as a
                    reviewer when assessing a candidates coding challenge
                    and how a candidate can avoid mistakes."
            />
            <MediumCard
                imageAuthorName="Hannah Busing"
                imageAuthorLink="https://unsplash.com/@hannahbusing"
                imageSourceName="Unsplash"
                imageSourceLink="https://unsplash.com/"
                image={Hannah_Busing}
                link="https://maulwurfio.medium.com/why-sbir-might-help-you-to-increase-your-teams-feedback-culture-1894fb727a99"
                title="Why SBIR might help you to increase your teams feedback culture"
                date="May 5th 2022"
                text="SBIR stands for Situation, Behaviour, Impact and
                Recommendation. It is a pattern/method that can be used
                to give feedback. It works best if it is used timely
                after the situation happened. Using it as a tool helps
                you to provide meaningful feedback which is more robust
                against wrong interpretation from the
                counterpart/feedback receiver."
            />
        </div>
    )
}

export default Medium
