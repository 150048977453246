import React from 'react'
import { Link } from 'react-router-dom'
import useStyles from './styles'

const Logo = () => {
    const classes = useStyles()

    return (
        <Link data-testid="link-logo" to="/" className={classes.Logo}>
            <span className={classes.Label}>Maulwurf</span>
            <span className={classes.LabelEnd}>.io</span>
        </Link>
    )
}

export default Logo
