/* eslint-disable prettier/prettier */
import { createUseStyles } from 'react-jss'
import { isMobile } from 'react-device-detect'

export default createUseStyles((theme) => ({
    MediumCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        borderRadius: theme.border.radius,
        padding: theme.spacing.medium,
        boxShadow: `${theme.colors.isDark
            ? 'rgba(50, 50, 50, 0.2) 0px 3px 5px 3px, rgba(0, 0, 0, 0.6) 0px 1px 3px 0px'
            : 'rgba(50, 50, 50, 0.2) 0px 2px 5px 1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'
            }`,
        margin: theme.spacing.xxsmall,
        maxWidth: isMobile ? 300 : 400,
        minWidth: isMobile ? 300 : 400,
        height: isMobile ? 640 : 580, // magic number
    },
    Title: {
        fontSize: '1.5rem',
        color: theme.colors.primary,
        marginBottom: theme.spacing.small,
    },
    Date: {
        color: theme.colors.secondary,
        marginBottom: theme.spacing.small,
    },
    Text: {
        fontSize: theme.font.size.small,
        color: theme.colors.text.onBackground,
        fontWeight: theme.font.weight.light,
        textAlign: 'justify',
    },
    Link: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing.medium,
    },
    Content: {
        display: 'flex',
        flexDirection: 'column'
    },
    Image: {
        maxWidth: isMobile ? 300 : 400,
        borderTopLeftRadius: theme.border.radius,
        borderTopRightRadius: theme.border.radius,
    },
    ImageAuthor: {
        fontSize: theme.font.size.small,
        color: theme.colors.text.onBackground,
        fontWeight: theme.font.weight.light,
    },
}))
