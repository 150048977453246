import React from 'react'
import Link from 'components/common/Link'
import useStyles from './styles'

const CVCard = () => {
    const classes = useStyles()

    return (
        <div className={classes.CVCard}>
            <div className={classes.Name}>Felix Maulwurf</div>
            <div className={classes.JobTitle}>Senior Frontend Engineer</div>
            <div className={classes.Attribute}>
                <div className={classes.Label}>Working at SAP Signavio</div>
                <div className={classes.Link} title="Open SAP Signavio">
                    <Link href="https://www.signavio.com/products/process-intelligence/" />
                </div>
            </div>
            <div className={classes.Attribute}>
                <div className={classes.Label}>M.Sc. in Media Informatics</div>
                <div
                    className={classes.Link}
                    title="Open Berliner Hochschule für Technik"
                >
                    <Link href="https://www.bht-berlin.de/en/m-mi" />
                </div>
            </div>
            <div className={classes.Attribute}>
                <div className={classes.Label}>My LinkedIn profile</div>
                <div className={classes.Link} title="Open LinkedIn profile">
                    <Link href="https://www.linkedin.com/in/felix-maulwurf-110388104" />
                </div>
            </div>
            <div className={classes.Attribute}>
                <div className={classes.Label}>My Medium profile</div>
                <div className={classes.Link} title="Open Medium profile">
                    <Link href="https://maulwurfio.medium.com/" />
                </div>
            </div>
        </div>
    )
}

export default CVCard
