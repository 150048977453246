import React from 'react'
import Menu from './components/Menu'
import MenuButton from './components/Menu/components/MenuButton'
import Logo from './components/Logo'
import ThemeSelector from './components/ThemeSelector'
import ThemeSelectorButton from './components/ThemeSelector/components/ThemeSelectorButton'
import useStyles from './styles'

const Header = () => {
    const classes = useStyles()
    return (
        <div className={classes.Header}>
            <MenuButton />
            <Menu />
            <Logo />
            <ThemeSelectorButton />
            <ThemeSelector />
        </div>
    )
}

export default Header
