import React from 'react'
import { ThemeProvider as ThemeProviderJSS } from 'react-jss'
import createPersistedState from 'use-persisted-state'
import theme from 'theme'
import generateComponentStyles from 'theme/components'
import * as DarkThemes from 'theme/colors/Dark'
import * as LightThemes from 'theme/colors/Light'

const useThemeState = createPersistedState('theme')

const getThemeColors = (themeName) => {
    const splitted = themeName.split('_')
    if (splitted[0] === 'light') {
        return Object.values(LightThemes)[Number(splitted[1])]
    }

    return Object.values(DarkThemes)[Number(splitted[1])]
}

const ThemeProvider = ({ children }) => {
    const [themeName, setThemeName] = useThemeState('light_0')

    // to prevent break if old theme names are stored
    if (!themeName.match(/(light_|dark_)/gi)) {
        setThemeName('light_1')
    }
    const themeMergedWithColors = {
        ...theme,
        colors: getThemeColors(themeName),
    }
    const themeMergedWithComponentStyles = {
        ...themeMergedWithColors,
        components: generateComponentStyles(themeMergedWithColors),
    }
    return (
        <ThemeProviderJSS theme={themeMergedWithComponentStyles}>
            {children}
        </ThemeProviderJSS>
    )
}

export default ThemeProvider
