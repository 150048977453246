import React, { useContext } from 'react'
import MenuIcon from '@material-ui/icons/Menu'
import AppContext from 'context'
import useStyles from './styles'

const MenuButton = () => {
    const { dispatch } = useContext(AppContext)
    const classes = useStyles()

    return (
        <button
            className={classes.MenuButton}
            type="button"
            onClick={() => dispatch({ type: 'onMenuClick' })}
            tabIndex={0}
            title="Menu"
        >
            <MenuIcon />
        </button>
    )
}

export default MenuButton
