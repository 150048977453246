import React, { useContext, useEffect, useState, useCallback } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { createPortal } from 'react-dom'
import AppContext from 'context'
import MenuItem from './components/MenuItem'
import useStyles from './styles'

const menuPortalElement = document.getElementById('menuportal')

const Menu = () => {
    const { state, dispatch } = useContext(AppContext)
    const [hide, setHide] = useState(true)
    const classes = useStyles({ hide })

    useEffect(() => {
        if (state.menuIsOpen) {
            setHide(false)
        }
    }, [state.menuIsOpen])

    const close = useCallback(() => {
        if (state.menuIsOpen) {
            setHide(true)
            setTimeout(() => dispatch({ type: 'onMenuClick' }), 500)
        }
    }, [dispatch, state.menuIsOpen])

    const handleKeyDown = useCallback(
        (event) => {
            if (event.keyCode === 27) {
                close()
            }
        },
        [close],
    )

    useEffect(() => {
        if (state.menuIsOpen) {
            document.addEventListener('keydown', handleKeyDown, false)
        } else {
            document.removeEventListener('keydown', handleKeyDown, false)
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown, false)
        }
    }, [handleKeyDown, state.menuIsOpen])

    if (!state.menuIsOpen) return null

    return createPortal(
        <div className={classes.Menu}>
            <div className={classes.Header}>
                <button
                    className={classes.Button}
                    type="button"
                    onClick={close}
                    aria-label="Show Menu"
                >
                    <CloseIcon />
                </button>
            </div>
            <div className={classes.MenuContent}>
                <MenuItem title="Home" routeTo="/" onClick={close} />
                <MenuItem title="About Me" routeTo="/about" onClick={close} />
                <MenuItem title="Blog" routeTo="/blog" onClick={close} />
                {/* <MenuItem
                    title="... is useState?"
                    routeTo="/blog/what-the-hook-part-1"
                    onClick={close}
                    level={2}
                    imageKey="hook-part1-header-thumb"
                />
                <MenuItem
                    title="... is useEffect?"
                    routeTo="/blog/what-the-hook-part-2"
                    onClick={close}
                    level={2}
                    imageKey="hook-part2-header-thumb"
                />
                <MenuItem
                    title="Experiments"
                    routeTo="/experiments"
                    onClick={close}
                />
                <MenuItem
                    title="Dynamic grid"
                    routeTo="/experiments/dynamic-grid"
                    level={2}
                    imageKey="dynamic-grid-thumb"
                    onClick={close}
                /> */}
            </div>
        </div>,
        menuPortalElement,
    )
}

export default Menu
