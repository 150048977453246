import { createUseStyles } from 'react-jss'
import { isMobile } from 'react-device-detect'

export default createUseStyles((theme) => ({
    Motivation: {
        padding: 20,
        marginTop: 20,
        maxWidth: isMobile ? '80%' : 500,
        minWidth: isMobile ? '80%' : 500,
    },
    Name: {
        extend: theme.semiBoldText,
        color: theme.colors.text.onBackground,
        fontSize: 24,
        borderBottom: `2px solid ${theme.colors.primary}`,
    },
    JobTitle: {
        extend: theme.regularText,
        color: theme.colors.secondary,
        marginBottom: 16,
        marginTop: 4,
    },
    Attribute: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        extend: theme.regularText,
        color: theme.colors.text.onBackground,
        marginTop: 8,
    },
    Label: {
        extend: theme.lightText,
        fontSize: 16,
    },
    Link: {},
    Primary: {
        color: theme.colors.primary,
    },
}))
