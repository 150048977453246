import { createUseStyles } from 'react-jss'

export default createUseStyles((theme) => ({
    Landing: {
        extend: theme.flexContainer({ flexDirection: 'column' }),
        overflow: 'hidden',
        margin: theme.spacing.medium,
        flexDirection: 'column',
        paddingBottom: theme.spacing.medium,
    },
    ScrollContainer: {
        overflow: 'auto',
        maxHeight: `calc(100vh - ${theme.constants.HEADER_HEIGHT}px)`,
        minHeight: `calc(100vh - ${theme.constants.HEADER_HEIGHT}px)`,
    },
    QuoteContainer: {
        marginTop: theme.spacing.medium,
    },
}))
