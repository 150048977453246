export const LightColors = {
    isDark: false,
    primary: '#F29A2E',
    secondary: '#55736D',
    background: '#FDFFFC',
    error: '#F25F5C',
    success: '#2A9D8F',
    contrast: '#353535',
    text: {
        onPrimary: '#FDFFFC',
        onSecondary: '#FDFFFC',
        onBackground: '#353535',
        onError: '#FDFFFC',
        onSuccess: '#FDFFFC',
        onContrast: '#FDFFFC',
    },
}

export const LightColors2 = {
    ...LightColors,
    primary: '#04BF8A',
    secondary: '#118ab2',
}

export const LightColors3 = {
    ...LightColors,
    primary: '#ce4257',
    secondary: '#ff7f51',
}

export const LightColors4 = {
    ...LightColors,
    primary: '#F27781',
    secondary: '#F29F05',
}

export const LightColors5 = {
    ...LightColors,
    primary: '#5F7ED9',
    secondary: '#262626',
}

export const LightColors6 = {
    ...LightColors,
    primary: '#247BA0',
    secondary: '#E9C46A',
}

export const LightColors7 = {
    ...LightColors,
    primary: '#60DBB6',
    secondary: '#4A44F2',
}

export const LightColors8 = {
    ...LightColors,
    primary: '#FC867C',
    secondary: '#7672E8',
}

export const LightColors9 = {
    ...LightColors,
    primary: '#F29C6B',
    secondary: '#0396A6',
}

export const LightColors10 = {
    ...LightColors,
    primary: '#6089BF',
    secondary: '#3F4D59',
}
