import React from 'react'
import Link from 'components/common/Link'
import ImageAuthorLine from './components/ImageAuthorLine'
import useStyles from './styles'

const MediumCard = ({
    link,
    title,
    date,
    text,
    image,
    imageAuthorName,
    imageAuthorLink,
    imageSourceName,
    imageSourceLink,
}) => {
    const classes = useStyles()

    return (
        <div className={classes.MediumCard}>
            <img className={classes.Image} src={image} alt="Medium Card" />
            <div className={classes.ImageAuthor}>
                <ImageAuthorLine
                    imageAuthorName={imageAuthorName}
                    imageAuthorLink={imageAuthorLink}
                    imageSourceName={imageSourceName}
                    imageSourceLink={imageSourceLink}
                />
            </div>
            <div className={classes.Content}>
                <span className={classes.Title}>{title}</span>
                <span className={classes.Date}>{date}</span>
                <span className={classes.Text}>{text}</span>
                <div className={classes.Link}>
                    <Link href={link} text="Read the full article on Medium" />
                </div>
            </div>
        </div>
    )
}

export default MediumCard
