import { createUseStyles } from 'react-jss'
import { isMobile } from 'react-device-detect'

export default createUseStyles((theme) => ({
    Menu: {
        extend: theme.flexContainer({ flexDirection: 'column' }),
        overflow: 'hidden',
        pointerEvents: 'auto',
        transition: `transform ${theme.transition.duration} ${theme.transition.easing}`,
        height: '100%',
        width: isMobile ? '100%' : theme.constants.SIDE_MENU_WIDTH,
        backgroundColor: theme.colors.background,
        borderRight: `${theme.border.width}px solid ${theme.colors.secondary}`,
        transform: ({ hide }) => {
            if (hide) {
                if (isMobile) return 'translate(-100%)'
                return `translate(-${theme.constants.SIDE_MENU_WIDTH}px)`
            }
            return 'none'
        },
    },
    Header: {
        extend: theme.flexContainer({ justifyContent: 'flex-end' }),
        flexGrow: 0,
        padding: theme.spacing.medium,
        paddingBottom: 0,
        width: `calc(100% - ${theme.spacing.large}px)`,
        marginBottom: theme.spacing.medium,
    },
    MenuContent: {
        extend: theme.flexContainer({
            justifyContent: 'flex-start',
            flexDirection: 'column',
            alignItems: 'flex-start',
        }),
        maxHeight: `calc(100% - ${theme.spacing.xlarge}px)`,
        overflow: 'auto',
        width: '100%',
        paddingTop: 1,
    },
    Button: {
        extend: theme.components.button,
    },
}))
