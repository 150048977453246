import React from 'react'
import useStyles from './styles'

const ImageAuthorLine = ({
    imageAuthorName,
    imageAuthorLink,
    imageSourceName,
    imageSourceLink,
}) => {
    const classes = useStyles()

    return [
        <span key="photo by">Photo by</span>,
        <a
            className={classes.ImageAuthorLink}
            href={imageAuthorLink}
            target="_blank"
            rel="noopener noreferrer"
            key="imageAuthorName"
        >
            {imageAuthorName}
        </a>,
        <span key="on">on</span>,
        <a
            className={classes.ImageAuthorLink}
            href={imageSourceLink}
            target="_blank"
            rel="noopener noreferrer"
            key="imageSourceName"
        >
            {imageSourceName}
        </a>,
    ]
}

export default ImageAuthorLine
