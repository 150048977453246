import useStyles from './styles'

const Quote = ({ quote, author }) => {
    const classes = useStyles()

    return (
        <div className={classes.QuoteContainer}>
            <div className={classes.Quote}>{quote}</div>
            <div className={classes.Author}>{author}</div>
            <div className={classes.BorderCover} />
            <div className={classes.BorderBox} />
        </div>
    )
}

export default Quote
