import { createUseStyles } from 'react-jss'
import { isMobile } from 'react-device-detect'

export default createUseStyles({
    About: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'auto',
        maxHeight: isMobile ? 'calc(100vh - 175px)' : 'calc(100vh - 75px)', // header height
        minHeight: isMobile ? 'calc(100vh - 175px)' : 'calc(100vh - 75px)',
        marginBottom: 20,
    },
})
