import React, { useContext } from 'react'
import PaletteIcon from '@material-ui/icons/Palette'
import AppContext from 'context'
import useStyles from './styles'

const ThemeSelectorButton = () => {
    const { dispatch } = useContext(AppContext)
    const classes = useStyles()

    return (
        <button
            className={classes.Button}
            type="button"
            onClick={() => dispatch({ type: 'onThemeSelectorClick' })}
            tabIndex={0}
            title="Theme"
        >
            <PaletteIcon />
        </button>
    )
}

export default ThemeSelectorButton
