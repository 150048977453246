export const DarkColors = {
    isDark: true,
    primary: '#6C6C6C',
    secondary: '#A6A6A6',
    background: '#353535',
    error: '#F25F5C',
    success: '#2A9D8F',
    contrast: '#FDFFFC',
    text: {
        onPrimary: '#FDFFFC',
        onSecondary: '#FDFFFC',
        onBackground: '#FDFFFC',
        onError: '#FDFFFC',
        onSuccess: '#FDFFFC',
        onContrast: '#353535',
    },
}

export const DarkColors2 = {
    ...DarkColors,
    primary: '#04BF8A',
    secondary: '#118ab2',
}

export const DarkColors3 = {
    ...DarkColors,
    primary: '#ce4257',
    secondary: '#ff7f51',
}

export const DarkColors4 = {
    ...DarkColors,
    primary: '#F27781',
    secondary: '#F29F05',
}

export const DarkColors5 = {
    ...DarkColors,
    primary: '#F2CF1D',
    secondary: '#04BF8A',
}

export const DarkColors6 = {
    ...DarkColors,
    primary: '#86B56B',
    secondary: '#E1F2D8',
}

export const DarkColors7 = {
    ...DarkColors,
    primary: '#FC867C',
    secondary: '#7672E8',
}

export const DarkColors8 = {
    ...DarkColors,
    primary: '#F29A2E',
    secondary: '#55736D',
}

export const DarkColors9 = {
    ...DarkColors,
    primary: '#986D81',
    secondary: '#3E667A',
}

export const DarkColors10 = {
    ...DarkColors,
    primary: '#F2B5D4',
    secondary: '#606EBF',
}
